import { useContext, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Transition,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import { HiMenu } from "react-icons/hi";
import {
  FaUpload,
  FaUserTie,
  FaShippingFast,
  FaHardHat,
  FaChartArea,
} from "react-icons/fa";
import { SalesContext } from "../../context/SalesContext";
//@ts-ignore
import LogoAcmeWhite from "../../resources/Logo-ACME-white.png";
import { isNil } from "lodash";

const fechaActual = (timestampActual: number) => {
  return new Date(timestampActual).toLocaleDateString("es-ES", {
    year: "numeric",
    month: "long",
    day: "numeric",
    // hour: "2-digit",
    // minute: "2-digit",
    // second: "2-digit",
  });
};

const MenuItemsBase = [
  {
    path: "/stats/upload",
    label: "Cargar excel",
    icon: <FaUpload className="size-4 fill-current" />,
    submenu: false,
  },
  {
    path: "/stats/group/ventas",
    label: "Ventas",
    icon: <FaUserTie />,
    submenu: true,
  },
  {
    path: "/stats/group/distribuidores",
    label: "Distribuidores",
    icon: <FaShippingFast />,
    submenu: true,
  },
  {
    path: "/stats/group/proyectos",
    label: "Proyectos",
    icon: <FaHardHat />,
    submenu: true,
  },
  {
    path: "/stats/general",
    label: "General",
    icon: <FaChartArea />,
    submenu: true,
  },
];

export default function Header() {
  const { data } = useContext(SalesContext);
  const route = useLocation();
  const date = useMemo(() => {
    if (!!data) {
      const { timestamp, ocTimestamp, quotesTimestamp, salesTimestamp } = data!;
      const values = [timestamp, ocTimestamp, quotesTimestamp, salesTimestamp];
      return fechaActual(
        values.reduce((prev, current) => {
          const currentDate = !isNil(current)
            ? typeof current === "number"
              ? current
              : new Date(current).getTime()
            : 0;
          return currentDate > prev! ? current : prev;
        }, 0)!
      );
    }
  }, [data]);
  const menuItems = useMemo(() => {
    let taken = false;
    return MenuItemsBase.map((it) => {
      const enabled =
        route.pathname === it.path || it.submenu
          ? route.pathname.includes(it.path)
          : false;
      const enabled2 = taken ? false : enabled;
      if (enabled && !taken) {
        taken = true;
      }
      return {
        ...it,
        enabled: enabled2,
      };
    });
  }, [route]);

  return (
    <div className="bg-white flex flex-col w-full shadow">
      <div className="flex flex-row items-center justify-center gap-6 lg:gap-12 text-white bg-primary px-4 py-4 md:px-12 lg:pl-24">
        <div className="md:hidden col-span-1 flex  items-center justify-center">
          <Menu>
            <div>
              <MenuButton className="text-2xl flex items-center justify-center ml-4 sm:ml-2">
                <span className="sr-only">Open user menu</span>
                <HiMenu />
              </MenuButton>
            </div>

            <Transition
              enter="transition ease-out duration-75"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <MenuItems
                anchor="bottom end"
                className="w-32 origin-top-right rounded-xl shadow bg-white text-sm/6 text-neutral-700 [--anchor-gap:var(--spacing-1)] focus:outline-none"
              >
                {menuItems.map((it, idx) => (
                  <MenuItem key={`nav-sm-${it.path}`}>
                    {({ focus }) => {
                      return (
                        <Link to={it.path}>
                          <button
                            className={`group flex items-center gap-2 pt-2 px-3 font-header relative transition-all ${
                              it.enabled ? "fill-tertiary text-tertiary" : ""
                            } ${focus ? "bg-black/10" : ""}`}
                            style={{
                              fontWeight: 500,
                            }}
                          >
                            {it.icon}
                            {it.label}
                          </button>
                        </Link>
                      );
                    }}
                  </MenuItem>
                ))}
                <div className="my-1 h-px bg-white/5" />
              </MenuItems>
            </Transition>
          </Menu>
        </div>
        <div className="flex-1 lg:flex-auto  font-normal font-serif text-2xl text-center md:text-start">
          <img src={LogoAcmeWhite} alt="Logo ACME" className="h-8" />
        </div>
        <div className="hidden md:flex flex-row gap-3">
          <Menu>
            {menuItems.map((item, index) => {
              return (
                <MenuItem key={`nav-${item.path}`}>
                  <Link to={item.path}>
                    <button
                      className={`group flex items-center gap-2 pt-2 px-3 data-[focus]:bg-white/10 font-header relative before:absolute before:w-1/2 before:scale-x-0  before:h-0.5 before:top-0 before:right-0 transition-all  before:bg-tertiary before:transition-all hover:before:scale-x-100 ${
                        item.enabled
                          ? "fill-tertiary text-tertiary before:scale-x-100"
                          : ""
                      }`}
                      style={{
                        fontWeight: 500,
                      }}
                    >
                      {item.icon}
                      {item.label}
                    </button>
                  </Link>
                </MenuItem>
              );
            })}
          </Menu>
        </div>

        <div className="text-right hidden lg:flex bg-secondary px-4 py-1.5 rounded-full text-neutral-900">
          <p className="font-body text-sm">{date}</p>
        </div>
      </div>

      <p className="px-2 flex flex-row flex-wrap text-xs gap-2 bg-secondary text-neutral-900 py-1.5 lg:hidden">
        <span>{date}</span>
      </p>
    </div>
  );
}
